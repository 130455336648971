const serviceData = [
    {
        id: 1,
        image: require('@/assets/images/companies/security.png'),
        text: 'Service de Sécurtié Nationnal',
        subtext: 'Accréditation de sécurité de haut niveau',
        status: 'En activité',
        date: '15 Oct, 2021'
    },
    {
        id: 2,
        image: require('@/assets/images/companies/security1.png'),
        text: 'Service de Gendarmerie Nationnal',
        subtext: 'Institution de protection de la population',
        status: 'En activité',
        date: '22 Oct, 2022'
    },
    {
        id: 3,
        image: require('@/assets/images/companies/security.png'),
        text: 'Service des Passeports',
        subtext: "Agences d'attribution des passeports",
        status: 'Désactivé',
        date: '13 Oct, 2022'
    },
    {
        id: 4,
        image: require('@/assets/images/companies/security1.png'),
        text: 'Police Nationale',
        subtext: 'Institution de maintien de l’ordre',
        status: 'Maintenance',
        date: '14 Oct, 2022'
    }
];

export { serviceData };